import {Component, Input, OnInit} from '@angular/core';
import {Indicateurs} from '../../../interfaces/Indicateurs';
import {ProjetService} from '../../../services/projet.service';
import {Sujet} from "../../../interfaces/ISujet";

@Component({
  selector: 'app-sujet-dernier-bulletin-section',
  templateUrl: './sujet-dernier-bulletin-section.component.html',
  styleUrls: ['./sujet-dernier-bulletin-section.component.scss'],
})
export class SujetDernierBulletinSectionComponent implements OnInit {
  @Input() indicateur_bulletins: Indicateurs;
  @Input() subject: Sujet;

  constructor(
      public projetService: ProjetService,
  ) { }

  ngOnInit() {}

}
