import {Component, Input, OnInit} from '@angular/core';
import {ProjetDocument} from '../../../interfaces/ProjetDocuments';
import {EVService} from '../../../services/ev.service';
import {Id} from '../../../interfaces/Id';
import {UtilsService} from "../../../services/utils.service";
import {IonicColorKeyEnum} from "../../../enums/IonicColorKeyEnum";
import {HttpResponse} from "@angular/common/http";
import {UrbaApiResponse} from "../../../enums/UrbaApiResponseStatus";
import {FileTypeEnum} from "../../../enums/FileTypeEnum";


@Component({
    selector: 'app-projet-documents-section',
    templateUrl: './projet-documents-section.component.html',
    styleUrls: ['./projet-documents-section.component.scss'],
})
export class ProjetDocumentsSectionComponent implements OnInit {
    @Input() fichiers: ProjetDocument[] = [];
    @Input() idProjet: Id;
    acceptedFileTypes = [
        FileTypeEnum.IMAGE,
        FileTypeEnum.PDF,
    ];

    sendingDocument = false;

    constructor(
        private ev: EVService,
        private utils: UtilsService,
    ) {
    }

    ngOnInit() {
    }

    async handleFileUpload(formData: FormData) {
        this.sendingDocument = true;
        // using Observable as Promise
        try {
            const res = await this.ev
                .post_projet_document(this.idProjet, formData)
                .toPromise() as HttpResponse<UrbaApiResponse<ProjetDocument>>;
            this.fichiers.unshift(res.body.data);
            await this.utils.toast(res.body.message, IonicColorKeyEnum.SUCCESS);

        } catch (err) {
            console.error(err);
            await this.utils.toast(err.message, IonicColorKeyEnum.DANGER);
        }

        this.sendingDocument = false;
    }

}
