import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IFormStep, MD_COLS_2} from '../../../interfaces/IFormStep';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {InputTypeEnum} from '../../../enums/InputTypeEnum';
import {FileTypeEnum} from '../../../enums/FileTypeEnum';
import {ModularValidatorService} from '../../../services/modular-validator.service';

@Component({
    selector: 'app-file-form',
    templateUrl: './file-form.component.html',
    styleUrls: ['./file-form.component.scss'],
})
export class FileFormComponent implements OnInit {
    @Input() title = 'ajouter_document';
    @Input() multiple = false;
    @Input() acceptedFileTypes: FileTypeEnum[] = [FileTypeEnum.IMAGE];
    @Output() onFileUpload = new EventEmitter<any>();
    @Input() errors: string[] = [];
    @Input() loading = false;
    @Input() fileInputName = 'file';
    @Input() legendInputName = 'legend';


    files = new FormArray([], [Validators.compose([
        Validators.required,
        this.validatorService.fileTypeValidator(this.acceptedFileTypes),
    ])]);

    form = new FormGroup({
        legend: new FormControl('', [Validators.required, Validators.minLength(3)]),
    });


    constructor(
        private validatorService: ModularValidatorService,
    ) {
    }

    handleSubmit() {
        const formData = new FormData();
        formData.append(this.fileInputName, this.files.controls[0]?.value?.file);
        formData.append(this.legendInputName, this.form.get('legend').value);

        return this.onFileUpload.emit(formData);
    }

    resetForm() {
        this.files.clear();
        this.form.get('legend').setValue('');
    }


    ngOnInit() {
    }

}
