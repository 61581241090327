import {Component, Input, OnInit} from '@angular/core';
import {EVService} from '../../../services/ev.service';
import {Id} from '../../../interfaces/Id';
import {ContactProjet} from '../../../interfaces/IProjet';


@Component({
    selector: 'app-projet-contacts-section',
    templateUrl: './projet-contacts-section.component.html',
    styleUrls: ['./projet-contacts-section.component.scss'],
})
export class ProjetContactsSectionComponent implements OnInit {
    @Input() idProjet: Id;
    @Input() contacts: ContactProjet[][] = [];
    @Input() mainContact: ContactProjet;

    constructor(
        private ev: EVService,
    ) {
    }

    ngOnInit() {
        this.ev.v6_projet_contacts(this.idProjet).subscribe({
            next: (contacts: ContactProjet[][]) => {
                console.log(contacts);
                this.contacts = contacts;
                this.mainContact = this.contacts[0].find(contact => contact.site_fonction === 'Responsable agro');
            },
            error: (err) => {
                console.error(err);
                this.contacts = [];
            },
        });
    }

}
