import {Component, Input, OnInit} from '@angular/core';
import {Id} from '../../../interfaces/Id';
import {ContactJuridique, ContactProjet} from '../../../interfaces/IProjet';

@Component({
    selector: 'app-contacts-all',
    templateUrl: './contacts-all.component.html',
    styleUrls: ['./contacts-all.component.scss'],
})
export class ContactsAllComponent implements OnInit {
    @Input() projets: {id_sit: Id, nom_sit: string}[] = [];
    @Input() contact: ContactJuridique;

    constructor() {
    }

    ngOnInit() {
    }

}
