import {Component, Input, OnInit} from '@angular/core';
import {ContactProjet} from '../../../interfaces/IProjet';

@Component({
    selector: 'app-person-card',
    templateUrl: './person-card.component.html',
    styleUrls: ['./person-card.component.scss'],
})
export class PersonCardComponent implements OnInit {
    @Input() person: ContactProjet;

    constructor() {
    }

    ngOnInit() {
    }

}
