import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as L from 'leaflet';

import 'leaflet.locatecontrol/dist/L.Control.Locate.min';
import 'leaflet.markercluster';
import 'leaflet-routing-machine';
import {TranslateService} from '@ngx-translate/core';
import {LocationEvent} from 'leaflet';
import * as Leaflet from 'leaflet';
import {IFormField, IFormFieldGeo, InputValue} from '../../../interfaces/IFormStep';
import {MapService} from '../../../services/map.service';
import {LeafletEventTriggerEnum} from '../../../enums/LeafletEventTriggerEnum';
import {IPosition} from '../../../interfaces/IPosition';

const FRANCE_COORD: IPosition = {
    lat: 46.8610951,
    lng: 3.6897517,
    zoom: 5,
};

@Component({
    selector: 'app-geo-input',
    templateUrl: './geo-input.component.html',
    styleUrls: ['./geo-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GeoInputComponent),
            multi: true
        }
    ]
})
export class GeoInputComponent implements ControlValueAccessor {
    @Input() field: IFormFieldGeo;
    @Input() control: FormControl;
    @Input() canSubmit: boolean = false;

    private map: L.Map;
    public marker: L.Marker;
    public markers: L.Marker[] = [];
    private group: L.FeatureGroup;
    public userLocation: L.LatLng;
    private routingControl: L.Routing.Control;
    private initialValue: L.LatLng | L.LatLng[];

    public showResetButton = false;
    public showMarkerOnMe = false;

    constructor(
        private translateService: TranslateService,
        private mapService: MapService,
    ) {
    }

    onChange: any = () => {
    }

    handleMapClick(e: L.LeafletMouseEvent): void {
        if (this.control.disabled) {
            return;
        }

        if (!this.field.multiple) {
            this.setMarkerOnPosition(e.latlng);
        } else {
            const marker = this.generateMarkerWithEvents(e.latlng);
            this.markers.push(marker);
            marker.addTo(this.map);
        }

        this.setInputValue();
    }

    bindMarkerEvents(marker: L.Marker) {
        marker.clearAllEventListeners();
        if (this.field.multiple) {
            marker.on(
                LeafletEventTriggerEnum.CONTEXT_MENU,
                () => {
                    if (this.control.enabled) {
                        this.deleteMarker(marker);
                    }
                });
        }
        marker.on(
            LeafletEventTriggerEnum.DRAG_END,
            (e) => {
                this.setInputValue();
                this.setRoutingControl();
            });
    }

    /**
     * Update the input value with this.marker (single) or this.markers (multiple)
     * positions.
     */
    setInputValue() {
        if (!this.field.multiple) {
            this.control.setValue(this.marker.getLatLng());
        } else {
            this.control.setValue(this.markers.map(marker => marker.getLatLng()));
        }
        this.control.markAsTouched();
        this.canShowMarkerOnMeBtn();
    }

    /**
     * Generate a L.Marker with basic stilling on a given position
     */
    generateMarker(position: L.LatLng): L.Marker {
        return L.marker(position, {
            draggable: true,
            icon: this.mapService.getIcon(),
        });
    }

    /**
     * Returns a L.Marker with all necessary events already bound
     */
    generateMarkerWithEvents(position: L.LatLng): L.Marker {
        const marker = this.generateMarker(position);
        this.bindMarkerEvents(marker);
        return marker;
    }

    /**
     * Removes a given L.Marker from the map but also removes it from this.markers array
     * and computes the new InputValue right away.
     */
    deleteMarker(marker: L.Marker, askForConfirmation: boolean = true) {
        if (askForConfirmation) {
            if (!confirm(this.translateService.instant('confirm_delete_marker'))) {
                return;
            }
        }
        this.markers = this.markers.filter(m => m !== marker);
        marker.remove();
        this.setInputValue();
    }

    onMapReady(map: L.Map): void {
        // Keeping current value as "initialValue" to allow for Reset
        if (this.control.value != null) {
            this.initialValue = this.control.value;
        }
        // Handle show of reset button
        this.onValueChange();

        // Binding field.disabled to control.disabled
        if (this.field.disabled === true) {
            this.control.disable({emitEvent: true});
        }

        // Set up the map
        this.map = map;

        // Adding initial tileLayer and layersControl
        this.mapService.getTileLayer().addTo(this.map);
        this.mapService.getLayersControl().addTo(this.map);

        // Set up all markers
        this.setMarkers();

        // Get the user's current position
        // @ts-ignore
        const lc = L.control.locate({
            // follow: true,
            setView: true,
            keepCurrentZoomLevel: false,
            icon: 'fa fa-location-arrow',
            metric: true,
            locateOptions: {
                enableHighAccuracy: true,
                maximumAge: 5000
            },
            onLocationError: (err) => console.error(err.message),
        }).addTo(this.map);

        // Setting up event Listeners
        this.map.on(
            LeafletEventTriggerEnum.CLICK,
            (e) => this.handleMapClick(e));

        lc.start(); // Show user position on load (no need to click the button)

        this.map.on(LeafletEventTriggerEnum.LOCATION_FOUND,
            (event: LocationEvent) => {
                if (this.userLocation == null) {
                    this.userLocation = event.latlng;
                    this.map.setView(this.userLocation, 13);
                    this.setRoutingControl();
                    this.recenterOnUserPosition();
                    this.canShowMarkerOnMeBtn();
                }
            }, {once: true});

        // Setting the initial view
        if (this.hasOnlyOneMarker()) {
            this.map.setView(this.marker.getLatLng(), 13);
        } else if (this.hasMultipleMarkers()) {
            this.group = L.featureGroup(this.markers);
        } else {
            this.map.setView(FRANCE_COORD, FRANCE_COORD.zoom);
        }

        // Needed to avoid graphical glitch
        setTimeout(() => {
            this.map.invalidateSize();
            if (this.group) {
                // WE HAVE to do this here, or else it won't work
                this.map.fitBounds(this.group.getBounds());
            }
        }, 1000);

        // Binding a callback to "disableChange" event
        this.control.registerOnDisabledChange(() => {
            // preventing Drag on "disabled = true"
            this.toggleAllMarkersDragEvent();
            // TODO
            this.canShowMarkerOnMeBtn();
        });

        this.toggleAllMarkersDragEvent();
    }

    canEnableRoutingControl(): boolean {
        return this.field.routing
            && this.marker != null
            && this.userLocation != null;
    }

    setRoutingControl(): void {
        if (!this.canEnableRoutingControl()) {
            return;
        }

        if (this.routingControl) {
            // Removing previous control
            this.map.removeControl(this.routingControl);
        }

        const itineraire = [
            this.marker.getLatLng(),
            this.userLocation,
        ];

        this.routingControl = Leaflet.Routing.control({
            router: Leaflet.Routing.osrmv1({
                serviceUrl: `https://router.project-osrm.org/route/v1/`,
                language: this.translateService.currentLang,
            }),
            showAlternatives: true,
            show: false,
            containerClassName: 'input-routing-control__container',
            alternativeClassName: 'input-routing-control__container-alt',
            routeWhileDragging: true,
            waypoints: itineraire,
            plan: Leaflet.Routing.plan(itineraire, {
                createMarker: () => {
                    return null;
                }
            }),
            addWaypoints: false,

        }).addTo(this.map);
    }

    resetMap(): void {
        if (!this.initialValue) {
            return;
        }
        if (!confirm(this.translateService.instant('reset_confirm'))) {
            return;
        }
        this.control.setValue(this.initialValue);
        this.setInputValue();
        this.clearMap();
        this.setMarkers();
        this.control.markAsUntouched();
        if (!this.field.multiple && this.userLocation != null) {
            this.setRoutingControl();
        }
        this.showResetButton = false;
    }

    clearMap(): void {
        if (this.marker) {
            this.deleteMarker(this.marker, false);
        }
        if (this.markers) {
            this.markers.forEach(m => this.deleteMarker(m, false));
        }
    }

    /**
     * Generates one or multiple markers depending on field.multiple
     * Bind all the required events and put them on the map
     */
    setMarkers(): void {
        // Set up the marker / markers
        if (this.field.multiple && Array.isArray(this.initialValue)) {
            for (const position of this.initialValue) {
                const marker = this.generateMarkerWithEvents(position);
                marker.addTo(this.map);
                this.markers.push(marker);
            }

        } else if (!this.field.multiple && this.initialValue != null) {
            this.marker = this.generateMarkerWithEvents(this.initialValue as L.LatLng);
            this.marker.addTo(this.map);
        }
    }

    isResetable(): boolean {
        return this.initialValue != null;
    }

    hasOnlyOneMarker(): boolean {
        return this.marker != null || this.markers.length === 1;
    }

    hasMultipleMarkers(): boolean {
        return this.markers.length > 1;
    }

    writeValue(value: any): void {
        this.control.setValue(value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    private onValueChange() {
        this.control.registerOnChange(() => {
            if (this.isResetable()) {
                this.showResetButton = true;
            }

            if (!this.field.multiple) {
                console.log(this.control.value);
                this.setMarkerOnPosition(this.control.value);
                this.recenterOnUserPosition();
            }
        });
    }

    /**
     * Enables/Disables the drag on every single input marker
     */
    toggleAllMarkersDragEvent() {
        this.map.eachLayer(l => {
            if (l instanceof L.Marker && this.isInputMarker(l)) {
                if (this.control.disabled) {
                    l.dragging.disable();
                } else {
                    l.dragging.enable();
                }
            }
        });
    }

    /**
     * On Single input only, generate or move this.marker on currentuser Position
     * if user position is known.
     */
    putMarkerOnMe(): void {
        if (this.field.multiple || this.userLocation == null) {
            return;
        }
        if (this.marker == null) {
            this.marker = this.generateMarkerWithEvents(this.userLocation);
            this.marker.addTo(this.map);
        } else {
            this.marker.setLatLng(this.userLocation);
        }
        this.setInputValue();
        this.recenterOnUserPosition();
    }

    recenterOnUserPosition() {
        if (this.marker) {
            // Focusing on user position + this.marker
            const bounds = L.latLngBounds([
                this.marker.getLatLng(),
                this.userLocation,
            ]);
            setTimeout(() => {
                this.map.fitBounds(bounds);
            }, 1000);
        }
    }

    /**
     * Check if given L.Layer is a L.Marker and is one of this Input markers
     * (this.marker our this.markers)
     */
    isInputMarker(l: L.Layer) {
        return l instanceof L.Marker && (l === this.marker || this.markers.includes(l));
    }


    canShowMarkerOnMeBtn(): void {
        this.showMarkerOnMe = this.userLocation != null
            && !this.control.disabled
            && !this.field.multiple;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    private getMarkersAsQueryParam(): string {
        return this.markers.map(m => {
            return `${m.getLatLng().lat},${m.getLatLng().lng}`;
        }).join('/');
    }

    private getUserLocationAsQueryParam(): string {
        if (this.userLocation == null) return '';
        return `${this.userLocation.lat},${this.userLocation.lng}/`;
    }

    public seeMarkersOnGoogleMaps() {
        const GOOGLE_MAP_URL = 'https://www.google.com/maps/dir/';
        window.open(`${GOOGLE_MAP_URL}${this.getUserLocationAsQueryParam()}${this.getMarkersAsQueryParam()}`, '_blank');
    }

    private setMarkerOnPosition(position: L.LatLng) {
        if (this.marker == null) {
            this.marker = this.generateMarkerWithEvents(position);
            this.marker.addTo(this.map);

            if (!this.field.multiple && this.userLocation) {
                this.setRoutingControl();
            }
        } else {
            this.marker.setLatLng(position);
        }

        this.setRoutingControl();
    }

}
