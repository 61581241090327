import {Component, Input, OnInit} from '@angular/core';
import {RemarqueSujet} from '../../../interfaces/Diag/RemarqueSujet';

@Component({
    selector: 'app-remarque-sujet-item',
    templateUrl: './remarque-sujet-item.component.html',
    styleUrls: ['./remarque-sujet-item.component.scss'],
})
export class RemarqueSujetItemComponent implements OnInit {
    @Input() remarque: RemarqueSujet;

    constructor() {
    }

    ngOnInit() {
    }
}
