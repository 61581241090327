import {Component, OnInit} from '@angular/core';
import {StorageService} from "../../services/storage.service";

@Component({
    selector: 'app-install-prompt',
    templateUrl: './install-prompt.component.html',
    styleUrls: ['./install-prompt.component.scss']
})
export class InstallPromptComponent implements OnInit {
    HAS_SEEN_INSTALL_PROMPT = 'HAVE_SEEN_INSTALL_PROMPT';

    private deferredPrompt: any;
    public showInstallBanner = false;

    constructor(
        private storage: StorageService,
    ) {
    }

    ngOnInit(): void {
        window.addEventListener('beforeinstallprompt', (event) => {
            event.preventDefault();
            this.deferredPrompt = event;
            this.showInstallBanner = !this.storage.get<boolean>(this.HAS_SEEN_INSTALL_PROMPT);
        });
    }

    installApp(): void {
        if (this.deferredPrompt) {
            this.deferredPrompt.prompt();
            this.deferredPrompt.userChoice.then((choiceResult: any) => {
                this.storage.set<boolean>(this.HAS_SEEN_INSTALL_PROMPT, true);
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                this.deferredPrompt = null;
                this.showInstallBanner = false;
            });
        }
    }

    closeBanner(): void {
        this.storage.set<boolean>(this.HAS_SEEN_INSTALL_PROMPT, true);
        this.showInstallBanner = false;
    }
}
