import {Component, Input, OnInit} from '@angular/core';
import {ProjetDocument} from '../../../interfaces/ProjetDocuments';

@Component({
  selector: 'app-document-item',
  templateUrl: './document-item.component.html',
  styleUrls: ['./document-item.component.scss'],
})
export class DocumentItemComponent implements OnInit {
  @Input() projectDocument: ProjetDocument;

  constructor(
  ) { }

  ngOnInit() {
  }

  downloadFile() {
  }

}
