import {Component, Input, OnInit} from '@angular/core';
import {Sujet} from '../../../interfaces/ISujet';
import {EVService} from '../../../services/ev.service';
import {RemarqueSujet} from '../../../interfaces/Diag/RemarqueSujet';
import {UtilsService} from '../../../services/utils.service';
import {AuthService} from '@auth0/auth0-angular';
import {IUser} from '../../../interfaces/IUser';
import {FormControl} from "@angular/forms";

@Component({
    selector: 'app-sujet-notes-section',
    templateUrl: './sujet-notes-section.component.html',
    styleUrls: ['./sujet-notes-section.component.scss'],
})
export class SujetNotesSectionComponent implements OnInit {
    @Input() subject: Sujet;
    @Input() remarques: RemarqueSujet[] = [];
    noteControl = new FormControl('');

    // noteContent = '';
    loading = false;
    success = null;
    user: IUser;

    constructor(
        private ev: EVService,
        private utils: UtilsService,
    ) {
    }

    ngOnInit() {
        if (this.remarques.length === 0 && this.subject) {
            this.getRemarques();
        }
    }

    getRemarques() {
        this.loading = true;

        this.ev.getRemarquesSujet(this.subject.id_suj).subscribe((remarques) => {
            this.remarques = remarques;
            // console.log(this.remarques);
            this.loading = false;
        });
    }

    postRemarque() {
        this.success = null;
        if (this.noteControl.value.trim().length === 0) {
            return;
        }

        const note = {
            id_suj: this.subject.id_suj,
            remarque: this.noteControl.value.trim(),
        };

        this.loading = true;
        this.ev.v6_sujet_remarque(note).subscribe({
            next: (res: any) => {
                this.noteControl.reset();

                // Creating a fack new note just for display to avoid re-fetching
                this.remarques.unshift({
                    name: 'vous',
                    remarque: note.remarque,
                    date_remarque: this.utils.dateToString(),
                    id_suj_remarque: Math.random(),
                    id_suj: this.subject.id_suj,
                    id_user: '----',
                });
                this.loading = false;
                this.success = true;
            },
            error: (err) => {
                this.success = false;
                this.loading = false;
            }
        });
    }


}
