import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wip',
  templateUrl: './wip.component.html',
  styleUrls: ['./wip.component.scss'],
})
export class WipComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
