import {Component, forwardRef, Input} from '@angular/core';
import {AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-input-simple',
    templateUrl: './input-simple.component.html',
    styleUrls: ['./input-simple.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputSimpleComponent),
            multi: true,
        },
    ],
})
export class InputSimpleComponent implements ControlValueAccessor {
    @Input() value = '';
    @Input() control?: AbstractControl | FormControl;
    @Input() label?: string;
    @Input() type = 'text';
    @Input() placeholder = '';
    @Input() inputClass: string;

    // Icons
    @Input() prefix?: string;
    @Input() suffix?: string;

    private onChange: (value: any) => void = () => {
    }
    private onTouched: () => void = () => {
    }

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    // Registers a callback to notify the form model of touch events
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    // Optional: Handles the disabled state of the input
    setDisabledState?(isDisabled: boolean): void {
        // Implement this if necessary
    }

    // Handles the `input` event from the template
    onInput(event: Event): void {
        const value = (event.target as HTMLInputElement).value;
        this.value = value;
        this.onChange(value); // Notify the form model of the value change
    }

    // Handles the `blur` event from the template
    onBlur(): void {
        this.onTouched(); // Notify the form model that the input was touched
    }

}
