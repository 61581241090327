import {Component, Input, OnInit} from '@angular/core';
import {Indicateurs} from '../../../interfaces/Indicateurs';
import {InputTypeEnum} from '../../../enums/InputTypeEnum';
import {FormControl, Validators} from '@angular/forms';
import {IFormFieldGeo} from '../../../interfaces/IFormStep';
import {IPosition} from '../../../interfaces/IPosition';
import {EVService} from '../../../services/ev.service';
import {Sujet} from '../../../interfaces/ISujet';
import {UtilsService} from '../../../services/utils.service';
import {IonicColorKeyEnum} from '../../../enums/IonicColorKeyEnum';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-sujet-localisation-section',
    templateUrl: './sujet-localisation-section.component.html',
    styleUrls: ['./sujet-localisation-section.component.scss'],
})
export class SujetLocalisationSectionComponent implements OnInit {
    @Input() subject: Sujet;
    @Input() indicateurs_localisation: Indicateurs;
    @Input() position: IPosition;

    control = new FormControl();

    field: IFormFieldGeo = {
        key: 'sujet_details_localisation',
        type: InputTypeEnum.GEO,
        control: [null, Validators.required],
        required: true,
        routing: false,
        submit: true,
    };

    constructor(
        private ev: EVService,
        private utils: UtilsService,
        private translator: TranslateService
    ) {
    }

    ngOnInit() {
        this.control.setValue(this.position);
    }

    handleSubmit() {
        if (!confirm(this.translator.instant('confirm_position_change'))) {
            return;
        }
        this.control.disable();

        this.ev.post_sujet_position(this.subject.id_suj, this.control.value).subscribe({
            next: (res) => {
                this.utils.toast(res.message, IonicColorKeyEnum.SUCCESS);
                this.control.enable();
            },
            error: (err) => {
                console.error(err);
                this.utils.toast(err.message, IonicColorKeyEnum.DANGER);
                this.control.enable();
            },
        });
    }

    handleTextPositionChanged(value: string, key: string) {
        this.control.setValue({...this.control.value, [key]: Number(value)});
    }
}
