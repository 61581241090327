import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Filter} from '../../../interfaces/Filter';

@Component({
    selector: 'app-filter-list-toggle',
    templateUrl: './filter-list-toggle.component.html',
    styleUrls: ['./filter-list-toggle.component.scss'],
})
export class FilterListToggleComponent<T> implements OnInit {
    @Input() label = 'filters';
    @Input() filters: Filter<T>[] = [];
    @Input() count: number = 0;
    @Output() filtersChanged = new EventEmitter<void>();

    maxCount = 0;

    constructor() {
    }

    ngOnInit() {
        this.updateView();
    }

    updateView() {
        this.maxCount = this.filters.length;
    }

    onFilterChanged() {
        this.filtersChanged.emit();
    }

}
