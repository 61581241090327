import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from "../../../services/utils.service";

@Component({
    selector: 'app-keyval',
    templateUrl: './keyval.component.html',
    styleUrls: ['./keyval.component.scss'],
})
export class KeyvalComponent implements OnInit {
    @Input() label: string;
    @Input() value: any;

    constructor(
        public utils: UtilsService,
    ) {
    }

    ngOnInit() {
    }

}
