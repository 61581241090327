import {NgModule} from '@angular/core';
import {ExtraOptions, PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@auth0/auth0-angular';
import {SuperadminGuard} from './superadmin.guard';
import {InstallationGuard} from './guard/installation.guard';
import {UrbasenseRoleGuard} from './guard/urbasense-role-guard.service';
import {UserRolesEnum} from './enums/UserRolesEnum';

export const routingConfiguration: ExtraOptions = {
    paramsInheritanceStrategy: 'always',
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'ignore',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
};


const routes: Routes = [
    {
        path: 'accueil',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
        canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
    },
    {
        path: '',
        redirectTo: 'accueil',
        // redirectTo: 'intro',
        pathMatch: 'full'
    },
    {
        path: 'place-detail',
        loadChildren: () => import('./place-detail/place-detail.module').then(m => m.PlaceDetailPageModule),
        canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
    },
    {
        path: 'site',
        loadChildren: () => import('./pages/detail-projet/detail-projet.module').then(m => m.DetailProjetPageModule),
        canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
    },
    {
        path: 'photos',
        loadChildren: () => import('./photos/photos.module').then(m => m.PhotosPageModule),
        canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
    },
    {
        path: 'interventions',
        loadChildren: () => import('./interventions/interventions.module').then(m => m.InterventionsPageModule)
    },
    {
        path: 'sites',
        loadChildren: () => import('./sites/sites.module').then(m => m.SitesPageModule)
    },
    {
        path: 'actualites',
        loadChildren: () => import('./actualites/actualites.module').then(m => m.ActualitesPageModule),
        canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
    },
    {
        path: 'profil',
        loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule),
        canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
    },
    {
        path: 'interventions',
        loadChildren: () => import('./interventions/interventions.module').then(m => m.InterventionsPageModule),
        canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
    },
    {
        path: 'missions/:type_demandeur',
        loadChildren: () => import('./interventions/missions/missions.module').then(m => m.MissionsPageModule)
    },
    // {
    //     path: 'accueil/photos',
    //     redirectTo: '/accueil/actualites/photos',
    //     pathMatch: 'full'
    // },
    {
        path: 'minisense',
        loadChildren: () => import('./minisense/minisense.module').then(m => m.MinisensePageModule)
    },
    {
        path: 'tensio',
        loadChildren: () => import('./tensio/tensio.module').then(m => m.TensioPageModule)
    },
    {
        path: 'ev',
        loadChildren: () => import('./ev/ev.module').then(m => m.EvPageModule)
    },
    {
        path: 'installminisense',
        loadChildren: () => import('./installminisense/installminisense.module').then(m => m.InstallminisensePageModule),
        canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
    },
    {
        path: 'reprogrammer',
        loadChildren: () => import('./reprogrammer/reprogrammer.module').then(m => m.ReprogrammerPageModule)
    },
    {
        path: 'liste-programmes',
        loadChildren: () => import('./liste-programmes/liste-programmes.module').then(m => m.ListeProgrammesPageModule)
    },
    {
        path: 'modifications',
        loadChildren: () => import('./modifications/modifications.module').then(m => m.ModificationsPageModule)
    },
    {
        path: 'urbadata',
        loadChildren: () => import('./urbadata/urbadata.module').then(m => m.UrbadataPageModule)
    },
    {
        path: 'cahier-arrosage',
        loadChildren: () => import('./cahier-arrosage/cahier-arrosage.module').then(m => m.CahierArrosagePageModule)
    },
    {
        path: 'site/:id_projet/projet-watering/projet-programmation/reprogrammer-projet',
        redirectTo: 'site/:id_projet/projet-watering/projet-programmation/reprogrammer-projet',
        pathMatch: 'full'
    },
    {
        path: 'site/:id_projet/projet-watering/liste-programme/nouveau-programme',
        redirectTo: 'site/:id_projet/projet-watering/liste-programme/nouveau-programme',
        pathMatch: 'full'
    },
    {
        path: 'site/:id_projet/groupe/:id_secteur',
        loadChildren: () => import('./pages/detail-secteur/detail-secteur.module').then(m => m.DetailSecteurPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'site/:id_projet/projet-watering/programmation-gen/sujet/:id_sujet',
        loadChildren: () => import('./pages/detail-sujet/detail-sujet.module').then(m => m.DetailSujetPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'site/:id_projet/groupe/:id_secteur/data/vegetaux/sujet/:id_sujet',
        loadChildren: () => import('./pages/detail-sujet/detail-sujet.module').then(m => m.DetailSujetPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'site/:id_projet/data/vegetaux/sujet/:id_sujet',
        loadChildren: () => import('./pages/detail-sujet/detail-sujet.module').then(m => m.DetailSujetPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'site/:id_site/equipement',
        redirectTo: 'site/:id_site/projethardware/equipement',
        pathMatch: 'full'
    },
    {
        path: 'site/:id_projet/data/vegetaux/sujet/:id_sujet/vegetal',
        redirectTo: 'site/:id_projet/data/vegetaux/sujet/:id_sujet',
        pathMatch: 'full'
    },
    {
        path: 'site/:id_projet/data/bulletins/detail/:id_bulletin',
        redirectTo: 'site/:id_projet/data/bulletins/detail/:id_bulletin',
        pathMatch: 'full'
    },
    {
        path: 'site/:id_projet/data/vegetaux/sujet/:id_sujet/courbes',
        redirectTo: 'site/:id_projet/data/vegetaux/sujet/:id_sujet',
        pathMatch: 'full'
    }, {
        path: 'site/:id_projet/data/vegetaux/sujet/:id_sujet/photos',
        redirectTo: 'site/:id_projet/data/vegetaux/sujet/:id_sujet',
        pathMatch: 'full'
    },
    {
        path: 'site/:id_projet/data/vegetaux/sujet/:id_sujet/carte',
        redirectTo: 'site/:id_projet/data/vegetaux/sujet/:id_sujet',
        pathMatch: 'full'
    },
    {
        path: 'site/:id_projet/data/vegetaux/sujet/:id_sujet/notes',
        redirectTo: 'site/:id_projet/data/vegetaux/sujet/:id_sujet',
        pathMatch: 'full'
    },


    {
        path: 'site/:id_projet/groupe/:id_secteur/data/vegetaux',
        redirectTo: 'site/:id_projet/groupe/:id_secteur/data/vegetaux',
        pathMatch: 'full'
    },
    {
        path: 'site/:id_projet/groupe/:id_secteur/watering',
        redirectTo: 'site/:id_projet/groupe/:id_secteur/data/watering',
        pathMatch: 'full'
    },

    {
        path: 'site/:id_projet/groupe/:id_secteur/data/watering/modifications',
        redirectTo: 'site/:id_projet/detail-secteur/:id_secteur/modifications',
        pathMatch: 'full'
    },

    {
        path: 'site/:id_projet/groupe/:id_secteur/data/watering/reprogrammer',
        redirectTo: 'site/:id_projet/detail-secteur/:id_secteur/reprogrammer',
        pathMatch: 'full'
    },
    {
        path: 'site/:id_projet/groupe/:id_secteur/liste-programme',
        redirectTo: 'site/:id_projet/detail-secteur/:id_secteur/liste-programme',
        pathMatch: 'full'
    },
    {
        path: 'site/:id_projet/groupe/:id_secteur/secteur-programmation',
        redirectTo: 'site/:id_projet/detail-secteur/:id_secteur/liste-programme',
        pathMatch: 'full'
    },


    {
        path: 'site/:id_projet/liste-programme',
        redirectTo: 'site/:id_projet/projet-watering/liste-programme',
        pathMatch: 'full'
    },
    {
        path: 'site/:id_projet/historique-ouverture',
        redirectTo: 'site/:id_projet/projet-watering/historique-ouverture',
        pathMatch: 'full'
    },
    {
        path: 'site/:id_projet',
        redirectTo: 'site/:id_projet',
        pathMatch: 'full'
    },
    {
        path: 'site/:id_projet/bulletins',
        redirectTo: 'site/:id_projet/data/bulletins',
        pathMatch: 'full'
    },
    {
        path: 'site/:id_projet/data/bulletins/detail/:id_relsp',
        redirectTo: 'site/:id_projet',
        pathMatch: 'full'
    },
    {
        path: 'site/:id_projet/map',
        redirectTo: 'site/:id_projet/data/map',
        pathMatch: 'full'
    },
    // {
    //   path: 'site/:id_projet/equipement',
    //   redirectTo: 'site/:id_projet/projethardware/equipement',
    //   pathMatch: 'full'
    // },
    {
        path: 'site/:id_projet/equipement/sujet/:id_sujet',
        loadChildren: () => import('./pages/detail-sujet/detail-sujet.module').then(m => m.DetailSujetPageModule)
    },
    {
        path: 'site/:id_projet/maintenance',
        redirectTo: 'site/:id_projet/projethardware/maintenance',

        pathMatch: 'full'
    },
    {
        path: 'site/:id_projet/projet-programmation',
        redirectTo: 'site/:id_projet/projet-watering/projet-programmation',
        pathMatch: 'full'
    },

    {
        path: 'site/:id_projet/projet-contacts',
        redirectTo: 'site/:id_projet/projet-contacts',
        pathMatch: 'full'
    },
    {
        path: 'site/:id_projet/liste-programme',
        redirectTo: 'site/:id_projet/projet-watering/liste-programme',
        pathMatch: 'full'
    },
    {
        path: 'site/:id_projet/historique-programme',
        redirectTo: 'site/:id_projet/projet-watering/historique-programme',
        pathMatch: 'full'
    },


    {
        path: 'site/:id_projet/groupe/:id_secteur/historique-programme-secteur',
        redirectTo: 'site/:id_projet/detail-secteur/:id_secteur/historique-programme-secteur',
        pathMatch: 'full'
    },
    {
        path: 'site/:id_projet/groupe/:id_secteur/historique-ouverture-secteur',
        redirectTo: 'site/:id_projet/detail-secteur/:id_secteur/historique-ouverture-secteur',
        pathMatch: 'full'
    },


    {
        path: 'site/:id_projet/projet-programmation',
        redirectTo: 'site/:id_projet/projet-watering/projet-programmation',
        pathMatch: 'full'
    },

    {
        path: 'site/:id_projet/historique-programme',
        redirectTo: 'site/:id_projet/projet-watering/historique-programme',
        pathMatch: 'full'
    },

    {
        path: 'site/:id_projet/ajouter',
        redirectTo: 'site/:id_projet/projethardware/ajouter',
        pathMatch: 'full'
    },


    {
        path: 'listevegetauxsecteur',
        loadChildren: () => import('./listevegetauxsecteur/listevegetauxsecteur.module').then(m => m.ListevegetauxsecteurPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'listevegetaux',
        loadChildren: () => import('./listevegetaux/listevegetaux.module').then(m => m.ListevegetauxPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'map',
        loadChildren: () => import('./map/map.module').then(m => m.MapPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'equipement-detail',
        loadChildren: () => import('./equipement-detail/equipement-detail.module').then(m => m.EquipementDetailPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'secteur-hardware',
        loadChildren: () => import('./secteur-hardware/secteur-hardware.module').then(m => m.SecteurHardwarePageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'equipement-secteur',
        loadChildren: () => import('./equipement-secteur/equipement-secteur.module').then(m => m.EquipementSecteurPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'cahier-arrosage',
        loadChildren: () => import('./cahier-arrosage/cahier-arrosage.module').then(m => m.CahierArrosagePageModule),
        canActivate: [AuthGuard]
    },
    // {
    //   path: 'projet-watering',
    //   loadChildren: () => import('./projet-watering/projet-watering.module').then( m => m.ProjetWateringPageModule),
    //   canActivate: [AuthGuard]
    // },
    {
        path: 'projet-programmation',
        loadChildren: () => import('./pages/projet-programmation/projet-programmation.module').then(m => m.ProjetProgrammationPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'liste-programme',
        loadChildren: () => import('./liste-programme/liste-programme.module').then(m => m.ListeProgrammePageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'historique-programme',
        loadChildren: () => import('./historique-programme/historique-programme.module').then(m => m.HistoriqueProgrammePageModule),
        canActivate: [AuthGuard]
    },
    // {
    //   path: 'projet-watering',
    //   loadChildren: () => import('./projet-watering/projet-watering.module').then( m => m.ProjetWateringPageModule),
    //   canActivate: [AuthGuard]
    // },
    {
        path: 'secteur-programmation',
        loadChildren: () => import('./secteur-programmation/secteur-programmation.module').then(m => m.SecteurProgrammationPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'gestion-interventions',
        loadChildren: () => import('./gestion-interventions/gestion-interventions.module').then(m => m.GestionInterventionsPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'nouveau-programme',
        loadChildren: () => import('./nouveau-programme/nouveau-programme.module').then(m => m.NouveauProgrammePageModule)
    },
    {
        path: 'historique-ouverture',
        loadChildren: () => import('./historique-ouverture/historique-ouverture.module').then(m => m.HistoriqueOuverturePageModule)
    },
    {
        path: 'detail-compteur',
        loadChildren: () => import('./detail-compteur/detail-compteur.module').then(m => m.DetailCompteurPageModule)
    },
    {
        path: 'historique-bulletin-secteur',
        loadChildren: () => import('./historique-bulletin-secteur/historique-bulletin-secteur.module').then(m => m.HistoriqueBulletinSecteurPageModule)
    },
    {
        path: 'sujet-edition',
        loadChildren: () => import('./sujet-edition/sujet-edition.module').then(m => m.SujetEditionPageModule)
    },
    {
        path: 'site/:id_projet/data/secteurs/secteur/:id_secteur/:typePage',
        loadChildren: () => import('./ajoutsecteur/ajoutsecteur.module').then(m => m.AjoutsecteurPageModule),
        canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
    },
    {
        path: 'site/:id_projet/data/secteurs/secteur/:typePage',
        loadChildren: () => import('./ajoutsecteur/ajoutsecteur.module').then(m => m.AjoutsecteurPageModule),
        canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
    },
    // {
    //   path: 'site/:id_projet/data/secteurs/secteur/:typePage',
    //   loadChildren: () => import('./ajoutsecteur/ajoutsecteur.module').then( m => m.AjoutsecteurPageModule)
    // },
    {
        path: 'nouveau-diagnostic',
        loadChildren: () => import('./nouveau-diagnostic/nouveau-diagnostic.module').then(m => m.NouveauDiagnosticPageModule)
    },
    {
        path: 'nouvelle-remarque',
        loadChildren: () => import('./nouvelle-remarque/nouvelle-remarque.module').then(m => m.NouvelleRemarquePageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'historique-programme-secteur',
        loadChildren: () => import('./historique-programme-secteur/historique-programme-secteur.module').then(m => m.HistoriqueProgrammeSecteurPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'historique-ouverture-secteur',
        loadChildren: () => import('./historique-ouverture-secteur/historique-ouverture-secteur.module').then(m => m.HistoriqueOuvertureSecteurPageModule)
    },
    {
        path: 'projet-contacts',
        loadChildren: () => import('./projet-contacts/projet-contacts.module').then(m => m.ProjetContactsPageModule)
    },
    {
        path: 'maintenance',
        loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenancePageModule)
    },
    {
        path: 'upload',
        loadChildren: () => import('./components/upload/upload.module').then(m => m.UploadPageModule)
    },
    {
        path: 'fin-installation',
        loadChildren: () => import('./fin-installation/fin-installation.module').then(m => m.FinInstallationPageModule)
    },
    {
        path: 'installation',
        loadChildren: () => import('./installation/installation.module').then(m => m.InstallationPageModule)
    },
    {
        path: 'intro',
        loadChildren: () => import('./pages/intro/intro.module').then(m => m.IntroPageModule)
    },
    {
        path: 'installminisensefin',
        loadChildren: () => import('./installminisensefin/installminisensefin.module').then(m => m.InstallminisensefinPageModule)
    },
    {
        path: 'ajout-vegetal',
        loadChildren: () => import('./ajout-vegetal/ajout-vegetal.module').then(m => m.AjoutVegetalPageModule)
    },
    {
        path: 'infos-sujet',
        loadChildren: () => import('./infos-sujet/infos-sujet.module').then(m => m.InfosSujetPageModule)
    },
    {
        path: 'map-sujet',
        loadChildren: () => import('./map-sujet/map-sujet.module').then(m => m.MapSujetPageModule)
    },
    {
        path: 'graphe-secteur',
        loadChildren: () => import('./graphe-secteur/graphe-secteur.module').then(m => m.GrapheSecteurPageModule)
    },
    {
        path: 'erreur',
        loadChildren: () => import('./erreur/erreur.module').then(m => m.ErreurPageModule)
    },
    {
        path: 'editionphoto',
        loadChildren: () => import('./editionphoto/editionphoto.module').then(m => m.EditionphotoPageModule)
    },
    {
        path: 'aidegeoloc',
        loadChildren: () => import('./aidegeoloc/aidegeoloc.module').then(m => m.AidegeolocPageModule)
    },
    {
        path: 'external/:token/groupe/:idgroupe/sujet/:idsujet/:onglet/:isLocked',
        loadChildren: () => import('./external/external.module').then(m => m.ExternalPageModule)
    },
    {
        path: 'external/:token/groupe/:idgroupe/sujet/:idsujet/:onglet',
        loadChildren: () => import('./external/external.module').then(m => m.ExternalPageModule)
    },
    {
        path: 'external/:token/groupe/:idgroupe/sujet/:idsujet',
        loadChildren: () => import('./external/external.module').then(m => m.ExternalPageModule)
    },
    {
        path: 'external/:token/groupe/:idgroupe',
        loadChildren: () => import('./external/external.module').then(m => m.ExternalPageModule)
    },
    {
        path: 'globalmap',
        loadChildren: () => import('./globalmap/globalmap.module').then(m => m.GlobalmapPageModule)
    },
    {
        path: 'graphe',
        loadChildren: () => import('./graphe/graphe.module').then(m => m.GraphePageModule)
    },
    {
        path: 'reprogrammation-new',
        loadChildren: () => import('./reprogrammation-new/reprogrammation-new.module').then(m => m.ReprogrammationNewPageModule)
    },
    {
        path: 'ajout-compteur',
        loadChildren: () => import('./ajout-compteur/ajout-compteur.module').then(m => m.AjoutCompteurPageModule)
    },
    {
        path: 'link-compteur-new',
        loadChildren: () => import('./link-compteur-new/link-compteur-new.module').then(m => m.LinkCompteurNewPageModule)
    },
    {
        path: 'adminarbre',
        loadChildren: () => import('./adminarbre/adminarbre.module').then(m => m.AdminarbrePageModule),
        canActivate: [SuperadminGuard], data: {
            expectedRole: 'SuperAdmin'
        },
        runGuardsAndResolvers: 'always'

    },
    {
        path: 'notification-suscriber',
        loadChildren: () => import('./notification-suscriber/notification-suscriber.module').then(m => m.NotificationSuscriberPageModule)
    },
    {
        path: 'trial',
        loadChildren: () => import('./trial/trial.module').then(m => m.TrialPageModule)
    },
    {
        path: 'docs',
        loadChildren: () => import('./docs/docs.module').then(m => m.DocsPageModule)
    },
    {
        path: 'site-router',
        loadChildren: () => import('./site-router/site-router.module').then(m => m.SiteRouterPageModule)
    },
    {
        path: 'sujet-graphes',
        loadChildren: () => import('./sujet-graphes/sujet-graphes.module').then(m => m.SujetGraphesPageModule)
    },
    {
        path: 'sujet-photos',
        loadChildren: () => import('./sujet-photos/sujet-photos.module').then(m => m.SujetPhotosPageModule)
    },
    {
        path: 'sujet-carte',
        loadChildren: () => import('./sujet-carte/sujet-carte.module').then(m => m.SujetCartePageModule)
    },
    {
        path: 'reprogrammationvoie',
        loadChildren: () => import('./reprogrammationvoie/reprogrammationvoie.module').then(m => m.ReprogrammationvoiePageModule)
    },
    {
        path: 'evsettings',
        loadChildren: () => import('./evsettings/evsettings.module').then(m => m.EvsettingsPageModule)
    },
    {
        path: 'listesecteurs',
        loadChildren: () => import('./listesecteurs/listesecteurs.module').then(m => m.ListesecteursPageModule)
    },
    // {
    //   path: 'secteur/:id_secteur/:typePage',
    //   loadChildren: () => import('./ajoutsecteur/ajoutsecteur.module').then( m => m.AjoutsecteurPageModule)

    // },

    // {
    //   path: 'ajoutsecteur',
    //   loadChildren: () => import('./ajoutsecteur/ajoutsecteur.module').then( m => m.AjoutsecteurPageModule)
    // },
    {
        path: 'modalesecteur',
        loadChildren: () => import('./modalesecteur/modalesecteur.module').then(m => m.ModalesecteurPageModule)
    },
    {
        path: 'events',
        loadChildren: () => import('./events/events.module').then(m => m.EventsPageModule)
    },
    {
        path: 'infosgenerales',
        loadChildren: () => import('./infosgenerales/infosgenerales.module').then(m => m.InfosgeneralesPageModule)
    },
    {
        path: 'exportdata',
        loadChildren: () => import('./exportdata/exportdata.module').then(m => m.ExportdataPageModule)
    },
    {
        path: 'admin-notifications',
        loadChildren: () => import('./admin-notifications/admin-notifications.module').then(m => m.AdminNotificationsPageModule)
    },
    {
        path: 'interventionlist',
        loadChildren: () => import('./interventions/interventionlist/interventionlist.module').then(m => m.InterventionlistPageModule)
    },
    {
        path: 'arrosage',
        loadChildren: () => import('./arrosage/arrosage.module').then(m => m.ArrosagePageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'arrosage/detailarrosage/:id_groupe',
        loadChildren: () => import('./detailarrosage/detailarrosage.module').then(m => m.DetailarrosagePageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'map-focused',
        loadChildren: () => import('./components/pages/map-focused/map-focused.module')
            .then(m => m.MapFocusedPageModule)
    },
    {
        path: 'guide-installation',
        loadChildren: () => import('./components/pages/guide-installation/guide-installation.module')
            .then(m => m.GuideInstallationPageModule)
    },
    {
        path: 'fiche-site-modal',
        loadChildren: () => import('./pages/fiche-site-modal/fiche-site-modal.module').then(m => m.FicheSiteModalPageModule)
    },
    {
        path: 'sujet-maintenance-modal',
        loadChildren: () => import('./pages/sujet-maintenance-modal/sujet-maintenance-modal.module')
            .then(m => m.SujetMaintenanceModalPageModule)
    },
    {
        path: 'sujet-installation-modal',
        loadChildren: () => import('./components/pages/sujet-installation-modal/sujet-installation-modal.module')
            .then(m => m.SujetInstallationModalPageModule)
    },
    {
        path: 'mission/:mission_id',
        loadChildren: () => import('./pages/mission-single/mission-single.module').then(m => m.MissionSinglePageModule),
        canActivate: [AuthGuard, InstallationGuard]
    },
    {
        // :mission_id is optionnal, so here we know we need to create a new mission
        path: 'mission',
        loadChildren: () => import('./pages/mission-single/mission-single.module').then(m => m.MissionSinglePageModule),
        canActivate: [AuthGuard, InstallationGuard], runGuardsAndResolvers: 'always'
    },
    {
        path: 'installation-form/:sujet_id/:intervention_id',
        loadChildren: () => import('./pages/installation-form/installation-form.module').then(m => m.InstallationFormPageModule),
        canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
    },
    {
        path: 'installation-diag-form/:intervention_id',
        loadChildren: () => import('./pages/installation-diag-form/installation-diag-form.module').then(m => m.InstallationDiagFormPageModule),
        canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
    },
    {
        // Intervention id is not required (wild maintenance)
        path: 'maintenance-form/:sujet_id',
        loadChildren: () => import('./pages/maintenance-form/maintenance-form.module').then(m => m.MaintenanceFormPageModule),
        canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
    },
    {
        path: 'maintenance-form/:sujet_id/:intervention_id',
        loadChildren: () => import('./pages/maintenance-form/maintenance-form.module').then(m => m.MaintenanceFormPageModule),
        canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
    },
    {
        path: 'maintenancemap',
        loadChildren: () => import('./maintenancemap/maintenancemap.module').then(m => m.MaintenancemapPageModule),
        canActivate: [UrbasenseRoleGuard], runGuardsAndResolvers: 'always',
        data: {roles: [UserRolesEnum.URBASENSE_CORPORATE]}
    },
    {
        path: 'diag/:diag_id',
        loadChildren: () => import('./pages/detail-diag/detail-diag.module').then(m => m.DetailDiagPageModule),
        canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
    },
    {
        path: 'diag-site-form/:site_id/:diag_type',
        loadChildren: () => import('./pages/diag-site-form/diag-site-form.module').then(m => m.DiagSiteFormPageModule),
        canActivate: [UrbasenseRoleGuard], runGuardsAndResolvers: 'always',
        data: {roles: [UserRolesEnum.URBASENSE_CORPORATE]}
    },
    {
        path: 'maintenancemap',
        loadChildren: () => import('./maintenancemap/maintenancemap.module').then(m => m.MaintenancemapPageModule),
        canActivate: [UrbasenseRoleGuard], runGuardsAndResolvers: 'always',
        data: {roles: [UserRolesEnum.URBASENSE_CORPORATE]}
    },
    {
        path: 'sujet/:id_sujet',
        loadChildren: () => import('./pages/single-sujet/single-sujet.module').then(m => m.SingleSujetPageModule),
        canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
    },
    {
        path: 'bulletins',
        loadChildren: () => import('./pages/archive-bulletins/archive-bulletins.module').then(m => m.ArchiveBulletinsPageModule),
        canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
    },
    {
        path: 'projet/:id_projet',
        loadChildren: () => import('./pages/single-projet/single-projet.module').then(m => m.SingleProjetPageModule),
        canActivate: [AuthGuard], runGuardsAndResolvers: 'always',
    },
    {
        path: 'help',
        loadChildren: () => import('./pages/help/help.module').then(m => m.HelpPageModule),
        canActivate: [AuthGuard], runGuardsAndResolvers: 'always',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, routingConfiguration)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
