import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Id} from '../../../interfaces/Id';
import {ISujetPhoto} from '../../../interfaces/ISujetPhoto';
import {environment} from '../../../../environments/environment';
import {EVService} from '../../../services/ev.service';
import {UtilsService} from '../../../services/utils.service';
import {IonicColorKeyEnum} from '../../../enums/IonicColorKeyEnum';

@Component({
    selector: 'app-sujet-galerie-carousel',
    templateUrl: './sujet-galerie-carousel.component.html',
    styleUrls: ['./sujet-galerie-carousel.component.scss'],
})
export class SujetGalerieCarouselComponent implements OnInit {
    @Input() subjectId: Id;
    @Input() photos: ISujetPhoto[] = [];
    @Input() selectedPhoto: ISujetPhoto;
    @Input() showActions = true;
    pathPrefix: string = environment.sujet_photo_url;

    @Output() selectedPhotoChange = new EventEmitter<ISujetPhoto>();
    changingFavorite = false;
    loadingImages = false;

    constructor(
        private ev: EVService,
        private utils: UtilsService,
    ) {
    }

    ngOnInit() {
        if (this.photos.length === 0 && this.subjectId != null) {
            this.loadingImages = true;
            this.ev.v6_sujet_photos(this.subjectId).subscribe({
                next: (res) => {
                    this.photos = res.photos;
                    this.loadingImages = false;
                },
                error: (err) => {
                    console.error(err);
                    this.loadingImages = false;
                }
            });
        }
    }

    handleCarouselItemSelected(id: string) {
        if (!this.showActions) {
            return;
        }
        this.selectedPhoto = this.photos.find(photo => photo.id === id);
        this.selectedPhotoChange.emit(this.selectedPhoto);
    }

    setFavorite() {
        this.changingFavorite = true;

        this.ev.post_sujet_photo_favorite(this.selectedPhoto.id).subscribe({
            next: async (res) => {
                this.photos = this.photos.map(p => {
                    p.is_favorite = p.id === this.selectedPhoto.id;
                    return p;
                });
                await this.utils.toast('favorite_updated', IonicColorKeyEnum.SUCCESS);
                this.changingFavorite = false;

            },
            error: async (err) => {
                console.error(err);
                await this.utils.toast('error', IonicColorKeyEnum.DANGER);
                this.changingFavorite = false;
            }
        });
    }

}
