import {Component, Input, OnInit} from '@angular/core';
import {Indicateurs} from '../../../interfaces/Indicateurs';
import {ContactJuridique, ContactProjet} from "../../../interfaces/IProjet";

@Component({
    selector: 'app-contact-data',
    templateUrl: './contact-data.component.html',
    styleUrls: ['./contact-data.component.scss'],
})
export class ContactDataComponent implements OnInit {
    @Input() imgSrc: string;
    @Input() title = 'contacts';
    @Input() contact: ContactProjet;
    @Input() contactJuridique: ContactJuridique;

    constructor() {
    }

    ngOnInit() {

    }

}
