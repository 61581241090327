import {Component, Input, OnInit} from '@angular/core';
import {Indicateurs} from '../../../interfaces/Indicateurs';
import {Sujet} from '../../../interfaces/ISujet';
import {UtilsService} from '../../../services/utils.service';

@Component({
    selector: 'app-sujet-stade-section',
    templateUrl: './sujet-stade-section.component.html',
    styleUrls: ['./sujet-stade-section.component.scss'],
})
export class SujetStadeSectionComponent implements OnInit {
    @Input() subject: Sujet;
    @Input() indicateurs_stade: Indicateurs;
    @Input() indicateurs_praticabilite: any[];

    constructor(
        public utils: UtilsService,
    ) {
    }

    ngOnInit() {
        this.indicateurs_praticabilite = this.indicateurs_stade.listeParams.map(key => {
            if (key.startsWith('pratic')) {
                return this.indicateurs_stade[key];
            }
            return null;
        }).filter((data) => data !== null);
    }
}
