import {Component, Input, OnInit} from '@angular/core';
import {Id} from '../../../interfaces/Id';

type SujetGroupe = {
    id_grp: Id;
    nom: string;
    type_arro?: string,
    type_preco: string;
    nb_suj: number;
    date_plantation: string; // Date
    detail: string;
    grp_archive: number | string; // 0
    sujets: any[]; // Sujet[] ?
};

@Component({
    selector: 'app-projet-groupe-section',
    templateUrl: './projet-groupe-section.component.html',
    styleUrls: ['./projet-groupe-section.component.scss'],
})
export class ProjetGroupeSectionComponent implements OnInit {
    @Input() groupes: SujetGroupe[] = [];

    constructor() {
    }

    ngOnInit() {
        // Delete duplicates
        this.groupes = this.groupes.filter((obj1, i, arr) => {
            return arr.findIndex(obj2 => (obj2.id_grp === obj1.id_grp)) === i;
        });
    }
}
