import {Directive, HostListener, Input, OnInit} from '@angular/core';


@Directive({
    selector: '[appScrollTo]',
})
export class ScrollToDirective implements OnInit {
    @Input('appScrollTo') targetId!: string;

    constructor() {
    }

    ngOnInit(): void {
        if (window.location.hash === `#${this.targetId}`) {
            // Timeout unfortunately necessary
            // Otherwise the scroll on page load "sometimes" doesn't work
            setTimeout(() => {
                this.focusTarget();
            }, 50);
        }
    }

    @HostListener('click') onClick(): void {
        // Adding Hash to current URL without updating the browser history
        const currentUrl = window.location.pathname;
        const newUrl = `${currentUrl.split('#')[0]}#${this.targetId}`;
        window.location.replace(newUrl);

        this.focusTarget();
    }

    private focusTarget(): void {
        const target = document.querySelector(`#${this.targetId}`);
        target?.scrollIntoView({block: 'nearest', inline: 'nearest'});
        if (!target.classList.contains('animate-highlight')) {
            target.addEventListener('animationend', () => {
                target.classList.remove('animate-highlight');
            }, {once: true});
            target.classList.add('animate-highlight');
        }
    }

}
