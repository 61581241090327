import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ISujetPhoto} from '../../../interfaces/ISujetPhoto';
import {environment} from '../../../../environments/environment';
import {FormControl} from '@angular/forms';
import {EVService} from '../../../services/ev.service';
import {Sujet} from '../../../interfaces/ISujet';
import {UtilsService} from '../../../services/utils.service';
import {IonicColorKeyEnum} from '../../../enums/IonicColorKeyEnum';
import {HttpResponse} from '@angular/common/http';
import {FileFormComponent} from '../file-form/file-form.component';
import {UrbaApiResponse} from '../../../enums/UrbaApiResponseStatus';

@Component({
    selector: 'app-sujet-galerie-section',
    templateUrl: './sujet-galerie-section.component.html',
    styleUrls: ['./sujet-galerie-section.component.scss'],
})
export class SujetGalerieSectionComponent implements OnInit {
    @ViewChild(FileFormComponent) fileFormComponent!: FileFormComponent;

    @Input() subject: Sujet;
    @Input() photos: ISujetPhoto[] = [];
    featuredPhoto: ISujetPhoto;
    prefix: string = environment.sujet_photo_url;
    sendingNewImage = false;
    isEditing = false;
    updatingLegend = false;

    legendControl = new FormControl('');

    constructor(
        private ev: EVService,
        private utils: UtilsService,
    ) {
    }

    ngOnInit() {
        if (this.photos.length > 0) {
            // Selecting "favorite" photo if any, or simply first photo
            this.featuredPhoto = this.photos.find(photo => photo.is_favorite) ?? this.photos[0];
            this.legendControl.setValue(this.featuredPhoto.legende ?? '');
        }
    }

    handleSelectedPhotoChanged(photo: ISujetPhoto) {
        this.featuredPhoto = photo;
        this.legendControl.setValue(this.featuredPhoto.legende ?? '');
        this.isEditing = false;
    }

    toggleEdit() {
        this.isEditing = !this.isEditing;
    }

    async handleUpdateLegend() {
        const legend = this.legendControl.value.trim();
        this.updatingLegend = true;

        this.ev.post_sujet_photo_legend(this.featuredPhoto.id, legend).subscribe({
            next: async (res) => {
                await this.utils.toast(res.message ?? 'legende_mise_a_jour', IonicColorKeyEnum.SUCCESS);
                this.featuredPhoto.legende = legend;
                this.isEditing = false;
                this.updatingLegend = false;
            },
            error: async (err) => {
                await this.utils.toast(err.message ?? 'error_save', IonicColorKeyEnum.DANGER);
                this.updatingLegend = false;
            }
        });
    }

    async handleFileUpload(formData: FormData) {
        this.sendingNewImage = true;

        try {
            // using Observable as Promise here caused a bug when updating this.photos
            const res = await this.ev
                .post_sujet_image(formData, this.subject.id_suj).toPromise() as HttpResponse<UrbaApiResponse<ISujetPhoto>>;

            const newImage = res.body.data;
            this.photos = [...this.photos, newImage];
            await this.utils.toast(res.body.message, IonicColorKeyEnum.SUCCESS);

            this.fileFormComponent.resetForm();

        } catch (err) {
            await this.utils.toast(err.body.message, IonicColorKeyEnum.DANGER);
        }

        this.sendingNewImage = false;
    }
}
