import {Injectable} from '@angular/core';

import {saveAs} from 'file-saver';
import {HttpClient} from '@angular/common/http';
import {UtilsService} from './services/utils.service';
import {IonicColorKeyEnum} from './enums/IonicColorKeyEnum';

@Injectable()
export class PdfDownloadService {

    constructor(
        private http: HttpClient,
        private utils: UtilsService,
    ) {
    }

    downloadFile(api: string, fileName: string, userToken: string) {
        console.log({api, fileName});
        api = 'https://arbre.urbasense.eu//Southside_api/getDocument.php?docurl=' + api + '&namedoc=' + fileName + '&image_token=' + userToken;
        this.http.get(api, {responseType: 'blob'})
            .subscribe(({
                next: (file: Blob) => {
                    // console.log({file, fileName});
                    saveAs(file, fileName ?? 'document.pdf');
                },
                error: async (err) => {
                    console.log(err);
                    await this.utils.toast('file_not_found', IonicColorKeyEnum.DANGER);
                }
            }));
    }

}
